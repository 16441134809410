import instance from './request'

//获取工单列表
export function getProcessList(dataObj) {
  return instance({
    url: 'productPlan/getProcedurePlanList',
    method: 'POST',
    params: dataObj
  })
}
//获取开始的工单列表
export function getStartProcessList(dataObj) {
  return instance({
    url: 'productPlan/getPlanList',
    method: 'POST',
    params: dataObj
  })
}

//根据开始的工单列表获取工序列表
export function getProcedureList(dataObj) {
  return instance({
    url: 'productPlan/getPlanProcedureList',
    method: 'POST',
    params: dataObj
  })
}

//开始工单
export function startWorkOrder(dataObj) {
  return instance({
    url: 'productPlan/beginPlan',
    method: 'POST',
    params: dataObj
  })
}

//结束工单
export function finishWorkOrder(dataObj) {
  return instance({
    url: 'productPlan/endPlan',
    method: 'POST',
    params: dataObj
  })
}