import instance from './request'

//获取工单列表
export function getBeginWorkOrder(dataObj) {
  return instance({
    url: 'New/productPlan/getProcedurePlanList',
    method: 'POST',
    params: dataObj
  })
}


//开始工单
export function beginWorkOrder(dataObj) {
  return instance({
    url: 'New/productPlan/beginProductPlan',
    method: 'POST',
    params: dataObj
  })
}

//获取开始工单的列表
export function getPlanList(dataObj) {
  return instance({
    url: 'New/productPlan/getPlanList',
    method: 'POST',
    params: dataObj
  })
}