<template>
	<div class="capacity-report">
		<div class="table-box">
			<div class="title">
				<span class="icon">
					<i :class="menuIcon"></i>
				</span>
				<span class="txt">{{menuName}}</span>
			</div>
			<div class="btn">

				<el-button @click="beginBtnClick()" type="success" icon="el-icon-video-play">
					开始工单
				</el-button>
			</div>

			<el-table class="table" ref="table" :data="tableData" :row-class-name="tableRowClassName" max-height="650"
				style="width: 100%;">
				<el-table-column label="工单号" prop="workOrderNo" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="产品编码" prop="productionCode" min-width="12.5%" align='center'></el-table-column>
				<el-table-column label="状态" min-width="12.5%" align='center'>
					<template slot-scope="scope">
						<el-tag :type="scope.row.Status === 1 ? 'primary' : 'info'">
							{{scope.row.Status | formatType}}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="计划数量" prop="taskQty" min-width="10%" align='center'></el-table-column>
				<el-table-column label="投入数量" prop="inputNum" min-width="10%" align='center'></el-table-column>
				<el-table-column label="完成数量" prop="outNum" min-width="10%" align='center'></el-table-column>
				<el-table-column label="不良数量" prop="badnessNum" min-width="10%" align='center'></el-table-column>
				<el-table-column label="操作" min-width="15%" align='center'>
					<template slot-scope="scope">
						<el-button v-if="scope.row.Status == 1" @click="handleWorkOrder(scope.row.productPlanId, 3)" type="warning" icon="el-icon-video-pause"
						 size="small">
							暂 停
						</el-button>
						<el-button v-if="scope.row.Status == 3" @click="handleWorkOrder(scope.row.productPlanId, 1)" type="success" icon="el-icon-video-play"
						 size="small">
							开 始
						</el-button>
						<el-button @click="handleWorkOrder(scope.row.productPlanId, 2)" :disabled="scope.row.Status == 2" type="danger"
						 icon="el-icon-switch-button" size="small">
							结 束
						</el-button>
					</template>
				</el-table-column>

			</el-table>
		</div>

		<el-dialog class="start-workorder" title="选择开始工单" :visible.sync="dialogFormVisible" width="60%" center>
			<el-row :gutter="20">
				<el-col :span="24">
					<el-card style="height: 30rem;">
						<div style="height: 100%;">
							<div class="title">工单列表</div>
							<el-form v-model="workerOrderListForm" ref="workerOrderListFormRef">
								<el-form-item prop="productPlanId">
									<div v-for="(item, index) in workOrederList">
										<el-radio-group v-model="workerOrderListForm.productPlanId" size="medium">
											<el-radio border :label="item.productPlanId" :key="item.productPlanId" :value="item.productPlanId">工单号：{{item.workOrderNo}}
												产品编码：{{item.productionCode}}</el-radio>
										</el-radio-group>
									</div>
								</el-form-item>
							</el-form>
						</div>
					</el-card>
				</el-col>
			</el-row>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="confirmBtnClick()">确 定</el-button>
				<el-button @click="cancelBtnClick()">取 消</el-button>
			</div>
		</el-dialog>


		<el-dialog class="end-work-dialog" title="填写结束工单信息" :visible.sync="dialogFormVisible1" width="30%" center>
			<el-form :model="endForm" ref="endForm" :rules="endFormRules" style="margin: 0 auto;width: 25rem;" label-position="left">
				<el-form-item label="产出数量" :label-width="formLabelWidth" class="form-item-box" prop="endForm.outNum">
					<el-input v-model="endForm.outNum" placeholder="0"></el-input>
				</el-form-item>
				<el-form-item label="不良数量" :label-width="formLabelWidth" class="form-item-box" prop="outForm.badnessNum">
					<el-input v-model="endForm.badnessNum" placeholder="0"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="endWorkOrder()">确 定</el-button>
				<el-button @click="dialogFormVisible1 = false">取 消</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getBeginWorkOrder,
		beginWorkOrder,
		getPlanList
	} from 'network/productManager'
	import {
		getProcessList,
		getStartProcessList,
		getProcedureList,
		startWorkOrder,
		finishWorkOrder
	} from 'network/capacityReport'
	export default {
		name: "capacityReport",
		data() {
			return {
				userId: window.sessionStorage.getItem("userId"),
				tableData: [], //表格数据
				tableHeight: 0, //表格高度
				dialogFormVisible: false, //开始工单弹窗是否显示
				workOrederList: [], //开始工单列表
				workerOrderListForm: {
					productPlanId: ""
				},
				dialogFormVisible1: false, //结束工单弹窗是否显示
				formLabelWidth: '120px', //表单label宽度
				//工单列表表单
				workOrederForm: {
					resource: []
				},
				//工序列表表单
				procedureListForm: {
					selectProcedureId: [], //选择工序的复选框
				},
				//结束工单表单
				endForm: {
					outNum: 0, //产出数量
					badnessNum: 0, //不良数量
				},
				endFormRules: {
					outNum: {
						required: true,
						message: '请输入产出数量',
						trigger: 'blur'
					},
					badnessNum: {
						required: true,
						message: '请输入不良数量',
						trigger: 'blur'
					}
				},
				//结束工单ID
				endPrid: -1,
				//是否可以结束工单
				isEnd: false
			}
		},
		filters: {
			formatType(val) {
				if (val == 0) {
					return "待开始"
				} else if (val == 1) {
					return "生产中"
				} else if (val == 2) {
					return "已完成"
				} else {
					return "已暂停"
				}
			}
		},
		props: {
			menuIcon: {
				type: String,
				default: ""
			},
			menuName: {
				type: String,
				default: ""
			}
		},
		created() {
			this.getTabelData();
			// this.getBeginWorkOrder1();
		},
		methods: {
			// 获取表格数据
			getTabelData() {
				let obj = {
					stationId: sessionStorage.getItem("stationId")
				}
				getBeginWorkOrder(obj).then(res => {
					this.tableData = res.data.data;
				})
			},
			//表格隔行变色
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (rowIndex % 2 === 0) {
					return 'success-row';
				}
				if(row.Status == 0) {
					return 'hidden-row';
				}
				return '';
			},
			//  获取工单列表
			getBeginWorkOrder1() {
				let obj = {
					stationId: sessionStorage.getItem("stationId")
				}
				getBeginWorkOrder(obj).then(res => {
					this.workOrederList = res.data.data;
				})
			},

			// 开始工单按钮 请求开始工单数据
			beginBtnClick() {
				this.dialogFormVisible = true;
				this.getBeginWorkOrder1();
			},

			// 处理工单 暂停工单，结束工单，开始工单
			handleWorkOrder(planId, type) {
				if (type == 2) {
					this.$confirm('此操作将会结束此工单, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						let obj = {
							planId: planId,
							deptId: sessionStorage.getItem("stationId"),
							userId: sessionStorage.getItem("userId"),
							type: type
						}
						beginWorkOrder(obj).then(res => {
							if (res.data.code !== 0) {
								return this.$message.error(res.data.msg);
							}
							this.$message.success(res.data.msg);
							this.getTabelData();
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消操作'
						});
					});
				}else {
					let obj = {
						planId: planId,
						deptId: sessionStorage.getItem("stationId"),
						userId: sessionStorage.getItem("userId"),
						type: type
					}
					beginWorkOrder(obj).then(res => {
						if (res.data.code !== 0) {
							return this.$message.error(res.data.msg);
						}
						this.$message.success(res.data.msg);
						this.getTabelData();
					})
				}
			},

			// 开始工单
			beginWorkOrder1(type) {
				if (this.workerOrderListForm.productPlanId == "" || this.workerOrderListForm.productPlanId == null) {
					return this.$message.error("请选择工单！");
				}
				let obj = {
					planId: this.workerOrderListForm.productPlanId,
					deptId: sessionStorage.getItem("stationId"),
					userId: sessionStorage.getItem("userId"),
					type: type
				}
				sessionStorage.setItem("productPlanId", this.workerOrderListForm.productPlanId);
				beginWorkOrder(obj).then(res => {
					if (res.data.code !== 0) {
						return this.$message.error(res.data.msg);
						this.dialogFormVisible = true;
					}
					this.dialogFormVisible = false;
					this.$message.success(res.data.msg);
					this.getTabelData();
				})
			},
			// 确定按钮开始工单
			confirmBtnClick() {
				// 发送请求
				this.beginWorkOrder1(1);
			},
			//  结束按钮取消开始工单
			cancelBtnClick() {
				this.dialogFormVisible = false
			},
			// 结束按钮点击事件
			endBtnClick(prid) {
				this.endPrid = prid
				this.dialogFormVisible1 = true
			},
		}
	}
</script>

<style lang="scss">
	.txt-form-box>label {
		font-size: 1rem !important;
		font-family: PingFang SC !important;
		font-weight: normal !important;
		color: #606266 !important;
	}

	.el-checkbox__inner {
		width: 1rem;
		height: 1rem;
	}

	.el-dialog__title {
		font-size: 1.25rem !important;
		margin: 1.25rem 0;
	}

	.el-checkbox__inner::after {
		height: 0.5rem !important;
		left: 0.3rem !important;
	}

	.remain-form-item .el-textarea__inner {
		color: red !important;
	}

	.el-checkbox__label {
		font-size: 1.125rem !important;
	}

	.end-work-dialog .el-dialog__title {
		font-size: 1.25rem !important;
		letter-spacing: 2px !important;
	}

	.form-item-box label {
		font-size: 1.125rem !important;
		color: #303133;
	}

	.card-box {
		margin: 1.25rem 0;
	}

	/*表格字体*/
	.el-table {
		font-size: 1.125rem !important;
	}

	/*表头样式*/
	.el-table th {
		background: rgba(245, 247, 247, 1) !important;
		font-size: 1.375rem !important;
		font-family: PingFang SC !important;
		font-weight: 500 !important;
		color: rgba(58, 132, 171, 1) !important;
	}

	.el-message__content {
		font-size: 1.125rem !important;
	}
	
	/* 表格隔行变色颜色 */
	.el-table .success-row {
		background: rgba(244,246,247,1);
	}
	.el-table .hidden-row {
		display: none;
	}
</style>
<style lang="scss" scoped>
	.capacity-report {
		width: 100%;
		height: 100%;
		overflow: hidden;
		overflow-y: scroll;
		border-radius: 0.625rem;
		box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, .1);
		position: relative;

		.title {
			width: 100%;
			display: flex;
			padding: 0.625rem;

			.icon {
				display: inline-block;
				width: 2.5rem;
				height: 2.5rem;
				text-align: center;
				line-height: 2.5rem;
				border-radius: 0.625rem;
				margin-right: 1.25rem;
				background: linear-gradient(0deg, rgba(41, 187, 192, 1) 0%, rgba(73, 208, 206, 1) 100%);

				i {
					font-size: 1.375rem;
					font-weight: 500;
					color: white;
				}
			}

			.txt {
				height: 2.5rem;
				font-size: 1.625rem;
				font-family: PingFang SC;
				font-weight: 500;
				color: rgba(58, 132, 171, 1);
				line-height: 2.5rem;
				letter-spacing: 3px;
			}
		}

		.el-card {
			overflow: hidden !important;
			overflow-y: scroll !important;

			.title {
				font-size: 1.25rem !important;
				font-family: PingFang SC;
				font-weight: normal;
				color: #606266;
				letter-spacing: 2px;
			}
		}

		.el-dialog {
			position: relative;

			.btn {
				position: absolute;
				top: 20px;
			}
		}

		.table-box {
			width: 100%;
			height: 100%;

			// position: relative;
			.btn {
				position: absolute;
				top: 0.5rem;
				right: 0.625rem;
			}

			.table {
				width: 100%;
				height: 100%;
				border-top: 1px solid #EBEEF5;
				overflow: hidden;
				overflow-y: scroll;
			}
		}

		.start-workorder {
			display: flex;
		}
	}
</style>
